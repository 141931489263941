import * as React from 'react';
import * as d3 from 'd3';
import GraphPopup from './GraphPopup';
import GraphKey from './GraphKey';
import {useEffect, useRef, useState} from "react";
import {Node} from '../../interfaces/Node';
import {Link} from '../../interfaces/Link'

interface Props {
    graph: any
    graphDepth: number
    loadGraphData: Function
    setGraphDepth: Function
}

export default function GraphVisual(props: Props) {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [dimensions, setDimensions] = useState({width: 0, height: 0});
    const [selectedNode, setSelectedNode] = useState<Node | null>(null);
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [nodes, setNodes] = useState<Node[]>([]);
    const [links, setLinks] = useState<Link[]>([]);
    var colorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(['Person', 'Skill', 'Project']).range(['#BB4430', '#7EBDC2', '#F3DFA2']);

    useEffect(() => {

        const handleResize = () => {
            if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                const offsetTop = rect.top + 80;
                const newHeight = window.innerHeight - offsetTop;

                setDimensions({height: newHeight, width: containerRef.current.clientWidth});
            }
        };

        // Update height on resize
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props.graph) {
            if (props.graph.nodes.length > 0) {
                setNodes(props.graph.nodes);
                setLinks(props.graph.links);
            }
        }
    }, [props.graph]);

    useEffect(() => {
        drawChart();
    }, [nodes, links]);

    useEffect(() => {
        drawChart(true);
    }, [window.innerHeight, window.innerWidth]);

    const drawChart = (refresh = true) => {
        const svg = d3.select(svgRef.current);

        if (refresh) {
            svg.selectAll('*').remove();
        }

        const simulation = d3.forceSimulation<Node>(nodes)
            .force('link', d3.forceLink<Node, Link>(links).id(d => d.id).distance(50))
            .force('charge', d3.forceManyBody().distanceMax(Math.min(dimensions.height / 8, dimensions.width / 8)).strength(-8))
            .force("collide", d3.forceCollide(30))
            .force("x", d3.forceX(dimensions.width / 2))
            .force("y", d3.forceY(dimensions.height / 2))
            // .force('center', d3.forceCenter(dimensions.width / 2, dimensions.height / 2))
            .force("bounds", boxingForce);

        function boxingForce() {

            for (let node of nodes) {
                // Of the positions exceed the box, set them to the boundary position.
                // You may want to include your nodes width to not overlap with the box.
                node.x = Math.max(-dimensions.width / 2, Math.min(dimensions.width, node.x!));
                node.y = Math.max(-dimensions.height / 2, Math.min(dimensions.height, node.y!));
            }
        }

        const link = svg.append('g')
            .attr('class', 'links')
            .selectAll('line')
            .data(links)
            .enter()
            .append('line')
            .attr('stroke', '#999')
            .attr('stroke-width', 1.5);

        const node = svg.append('g')
            .attr('class', 'nodes')
            .selectAll('circle')
            .data(nodes)
            .enter()
            .append('circle')
            .attr('r', 20)
            .attr('fill', d => colorScale(d.type.toString()))
            .call(d3.drag<SVGCircleElement, Node>()
                .on('start', (event, d) => dragStarted(event, d, simulation))
                .on('drag', (event, d) => dragged(event, d, dimensions.width, dimensions.height))
                .on('end', (event, d) => dragEnded(event, d, simulation)))
            .on('click', (event, d) => handleNodeClick(event, d))
            .on('dblclick', (event, d) => handleNodeExpand(event, d));


        simulation.on('tick', () => {
            link
                .attr('x1', d => (d.source as Node).x!)
                .attr('y1', d => (d.source as Node).y!)
                .attr('x2', d => (d.target as Node).x!)
                .attr('y2', d => (d.target as Node).y!);

            node
                .attr('cx', d => d.x!)
                .attr('cy', d => d.y!);
        });


        // // Fix NaN values issue
        // nodes.forEach(node => {
        //     node.x = node.x ?? dimensions.width / 2;
        //     node.y = node.y ?? dimensions.height / 2;
        // });


        simulation.nodes(nodes);
        simulation.force<d3.ForceLink<Node, Link>>('link')!.links(links);
        simulation.alpha(1).restart();
    };

    const dragStarted = (event: d3.D3DragEvent<SVGCircleElement, Node, Node>, d: Node, simulation: d3.Simulation<Node, undefined>) => {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
    };

    const dragged = (event: d3.D3DragEvent<SVGCircleElement, Node, Node>, d: Node, width: number, height: number) => {
        d.fx = Math.max(20, Math.min(width - 20, event.x));
        d.fy = Math.max(20, Math.min(height - 20, event.y));
    };

    const dragEnded = (event: d3.D3DragEvent<SVGCircleElement, Node, Node>, d: Node, simulation: d3.Simulation<Node, undefined>) => {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
    };

    const handleNodeClick = (event: any, d: Node) => {
        setSelectedNode(d);
        setPopupVisible(true);
    };

    const handleNodeExpand = async (event: any, d: Node) => {
        // props.setGraphDepth(props.graphDepth + 1)
        console.log(d.id);
        let graph = await props.loadGraphData([d.id], 1)
        console.log('explorer expanded is:', graph)
        console.log('nodes expanded are:', graph.nodes)
        console.log('links expanded are:', graph.links)
        addToChart(graph.links, graph.nodes)
    };


    function addToChart(newLinks: Link[], newNodes: Node[]) {
        // Filter out duplicate nodes (checking by id)
        newNodes = newNodes.filter(newNode => !nodes.some(existingNode => existingNode.id === newNode.id));

        // Filter out duplicate links (checking by source and target)
        newLinks = newLinks.filter(newLink =>
            !links.some(existingLink =>
                (existingLink.source === newLink.source && existingLink.target === newLink.target) ||
                (existingLink.source === newLink.target && existingLink.target === newLink.source) // check reverse
            )
        );

        console.log('new nodes', newNodes)
        console.log('new links', newLinks)

        // Push only the unique nodes and links
        // nodes.push(...newNodes);
        // links.push(...newLinks);

        setNodes((prevNodes) => [...prevNodes, ...newNodes]);
        setLinks((prevLinks) => [...prevLinks, ...newLinks]);

    }


    return (
        <div ref={containerRef} style={{width: '100%', height: '100%', position: 'relative'}}>
            <svg ref={svgRef} width={dimensions.width} height={dimensions.height}></svg>
            {selectedNode && (
                <GraphPopup node={selectedNode} visible={popupVisible} setVisibility={setPopupVisible}/>
            )}
            <GraphKey colors={[{color: colorScale('Person'), label: 'Person'}, {
                color: colorScale('Skill'),
                label: 'Skill'
            }, {color: colorScale('Project'), label: 'Project'}]}/>
        </div>
    );
}