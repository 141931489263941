import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Card,
    Tabs,
    Tab,
    Box,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer
} from '@mui/material'
import {useTheme} from "@mui/material/styles";


interface Relationships {
    [key: string]: any[];
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={index}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

interface ContentTabProps {
    section: string,
    relationships: Relationships,
}

function ContentTab(props: ContentTabProps) {
    const theme = useTheme();
    return (
        <CustomTabPanel value={props.section} index={props.section}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* Dynamically render the header based on the keys of the first item */}
                            {Object.keys(props.relationships[props.section][0])
                                .slice(1)  //remove type
                                .map((key: string, index: number) => (
                                    <TableCell sx={{backgroundColor: theme.palette.secondary.main, color: theme.palette.text.secondary, fontSize:'16px', fontWeight:'bold'}} key={index}>{key}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.relationships[props.section].map((item: any, index: number) => (
                            item.type === props.section && (
                                <TableRow key={index}>
                                    {Object.keys(props.relationships[props.section][0])
                                        .slice(1) //remove type
                                        .map((key: string, i: number) => (
                                            <TableCell key={i}>
                                                {item[key] !== undefined ? item[key] : ''}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            )
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </CustomTabPanel>
    )
}

interface Props {
    id: string | undefined
}

export default function ProfileContent(props: Props) {
    const [relationships, setRelationships] = useState<Relationships | null>(null);
    const [tab, setTab] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_BASE_URL + 'user/relationships',
            {'object_id': props.id},
            {
                withCredentials: true
            }
        ).then(response => {
            setRelationships(response.data as Relationships);
        }).catch(error => {
            console.error(error);
        });

    }, []);

    return (
        <Card>

            {!relationships && (<div>Nothing to see here...</div>)}

            {/*TABS*/}
            <Box sx={{maxWidth: '100%'}}>
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        style: {
                            display: 'none'
                        }
                    }}
                >
                    {relationships && Object.keys(relationships).map((section: string) => (
                        <Tab label={section + 's'}/>
                    ))}
                </Tabs>
            </Box>

            {/*CONTENT*/}
            {relationships && Object.keys(relationships).map((section: string) => (
                <ContentTab section={section} relationships={relationships}/>
            ))}
        </Card>
    )
}