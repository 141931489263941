import {useCallback, useState} from "react";
import axios from "axios";
import {useDropzone} from "react-dropzone";
import {Link, useNavigate} from "react-router-dom";
import {Card} from '@mui/material';

function Uploader() {
    const [file, setFile] = useState<File | null>(null); // Manage the selected file
    const [uploadProgress, setUploadProgress] = useState<number | null>(null); // Progress state
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // Error state
    const [isUploading, setIsUploading] = useState<boolean>(false); // Loading state
    const [successMessage, setSuccessMessage] = useState<string | null>(null); // Success state

    // Handle the drop event
    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]); // We only allow one file, so select the first one
            setErrorMessage(null); // Clear any previous errors
            setSuccessMessage(null); // Clear previous success messages
        }
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            // 'image/*': ['.jpeg', '.jpg', '.png', '.gif'],  // Accept image types
            'application/pdf': ['.pdf'],  // Accept PDFs
            'text/html': ['.html']  // Accept PDFs
        },
        multiple: false, // Only accept a single file
    });

    // Function to handle file upload
    const uploadFile = async () => {
        console.log('upload starting')
        if (!file) {
            setErrorMessage("No file selected. Please choose a file first.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            setIsUploading(true);
            setErrorMessage(null); // Clear any previous errors

            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + 'interaction/upload',
                formData,
                {

                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        if (progressEvent.loaded === progressEvent.total) {
                            setSuccessMessage('Processing...');
                        } else {
                            if (progressEvent.total) {  // Add this check for undefined
                                const percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) / progressEvent.total
                                );
                                setUploadProgress(percentCompleted); // Track upload progress
                            }
                        }
                    },
                }
            );
            console.log('uploaded')
            // Handle success
            setSuccessMessage("File uploaded successfully!");
            setFile(null); // Clear file after successful upload
            setUploadProgress(null);
        } catch (error) {
            setErrorMessage("File upload failed. Please try again.");
        } finally {
            setIsUploading(false);
        }
    };

    const navigate = useNavigate();


    return (
        <Card>
            <div
                {...getRootProps()}
                style={{
                    padding: "10px",
                    textAlign: "center",
                }}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop file here...</p>
                ) : (
                    <p>Add Information</p>
                )}
                {file && <p>Selected file: {file.name}</p>}
            </div>

            {file && !isUploading && (
                <button style={{
                    padding: "10px 16px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    backgroundColor: isUploading ? "#f0f0f0" : "#fff",
                    color: isUploading ? "#999" : "#333",
                    cursor: isUploading ? "not-allowed" : "pointer",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Consistent with the card style
                    opacity: isUploading ? 0.6 : 1, // Lower opacity when disabled
                    transition: "background-color 0.3s, opacity 0.3s",
                }} onClick={uploadFile} disabled={isUploading}>
                    Upload File
                </button>
            )}

            {uploadProgress !== null && (
                <div>
                    <p>Upload progress: {uploadProgress}%</p>
                </div>
            )}

            {isUploading && <p>Uploading...</p>}

            {errorMessage && <p style={{color: "red"}}>{errorMessage}</p>}
            {successMessage && <p style={{color: "green"}}>{successMessage}</p>}
        </Card>
    );
}

export default Uploader;
