import {useEffect, useState} from "react";
import axios from "axios";
import * as React from 'react';
import {Grid2, Typography, TableRow, TableHead, TableContainer, TableCell, TableBody, Table} from '@mui/material';
import {FaPlus, FaSearch} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

export interface MeetingInterface {
    id: number
    title: string
    description: string
    location: string | undefined
    start_datetime: string | undefined
    end_datetime: string | undefined
}

export interface AttendeeInterface {
    id: number
    email: string
}

export interface AgendaInterface {
    id: number
    content: string
    created: Date | undefined
}

export interface MinutesInterface {
    id: number
    content: string
    created: Date | undefined
}


interface MeetingItemProps {
    meeting: MeetingInterface
}

function Row(props: MeetingItemProps) {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <TableRow>
                <TableCell >{props.meeting.title}</TableCell>
                <TableCell>{props.meeting.description}</TableCell>
                <TableCell><FaSearch
                    onClick={() => navigate('/meeting/' + props.meeting.id)}></FaSearch><FaPlus></FaPlus></TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function Meeting() {
    const theme = useTheme()
    const [meetings, setMeetings] = useState<MeetingInterface[]>([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + 'meeting/list',
            {
                withCredentials: true
            }
        ).then(response => {
            setMeetings(response.data as MeetingInterface[]);
        }).catch(error => {
            console.error(error);
        });
    }, []);


    return (
        <Grid2 size={12}>
            <TableContainer className="background-card">
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow sx={{bgcolor: theme.palette.primary.main}}>
                            <TableCell sx={{backgroundColor: theme.palette.secondary.main, color: theme.palette.text.secondary, fontSize:'16px', fontWeight:'bold'}}>
                                    Title
                            </TableCell>
                            <TableCell sx={{backgroundColor: theme.palette.secondary.main, color: theme.palette.text.secondary, fontSize:'16px', fontWeight:'bold'}}>
                                    Description
                            </TableCell>
                            <TableCell sx={{backgroundColor: theme.palette.secondary.main, color: theme.palette.text.secondary, fontSize:'16px', fontWeight:'bold'}}>
                                    Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {meetings && meetings.map((meeting: MeetingInterface) => (
                            <Row key={meeting.id} meeting={meeting}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid2>
    )
}