import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "../components/auth/AuthProvider";
import {Link} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {Typography} from "@mui/material";

const Logout = () => {
    const {logout} = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '20px'
        }}>
            <Typography variant="h4" style={{padding: '20px'}}>Are you sure you want to logout?</Typography>

            <div style={{display: 'flex', gap: '10px'}}>
                <Link to={'/'}>
                    <button
                        style={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            color: '#fff',
                            backgroundColor: theme.palette.primary.main,
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                            display: 'inline-block',
                        }}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = theme.palette.primary.main}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = theme.palette.primary.main}
                    >
                        Go Back
                    </button>
                </Link>
                <button
                    onClick={handleLogout}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        color: '#fff',
                        backgroundColor: '#d9534f',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        display: 'inline-block',
                        transition: 'background-color 0.3s',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#c9302c'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#d9534f'}
                >
                    Logout
                </button>
            </div>

        </div>
    );
};

export default Logout;
