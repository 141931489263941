import * as React from 'react';
import axios from "axios";
import {useNavigate, Link} from 'react-router-dom';
import {useAuth} from "src/components/auth/AuthProvider";
import {Card, Stack, Typography, TextField, FormControl, FormLabel, Button, Box} from '@mui/material'

export default function Login() {
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const navigate = useNavigate();
    const {login} = useAuth();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });

        axios.post(process.env.REACT_APP_API_BASE_URL + 'auth/login',
            {
                email: data.get('email'),
                password: data.get('password')
            },
            {
                withCredentials: true
            }
        ).then(response => {
            console.log(response);

            login(response.data.user);
            navigate('/');
        }).catch(error => {
            console.error(error);
        });
    };

    const validateInputs = () => {
        const email = document.getElementById('email') as HTMLInputElement;
        const password = document.getElementById('password') as HTMLInputElement;

        let isValid = true;

        if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
            setEmailError(true);
            setEmailErrorMessage('Please enter a valid email address.');
            isValid = false;
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }

        if (!password.value || password.value.length < 6) {
            setPasswordError(true);
            setPasswordErrorMessage('Password must be at least 6 characters long.');
            isValid = false;
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        return isValid;
    };

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{width: 1, height: "100vh"}}
        >
            <Card variant={'outlined'} sx={{justifyContent: "space-between", width: "400px"}}>
                <Typography
                    component="h1"
                    variant="h4"
                    sx={{width: '100%', marginBottom: '20px'}}
                >
                    Sign in
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: 2,
                    }}
                >
                    <FormControl>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <FormLabel sx={{color: 'text.primary'}} htmlFor="email">Email</FormLabel>
                        </Box>
                        <TextField
                            error={emailError}
                            helperText={emailErrorMessage}
                            id="email"
                            type="email"
                            name="email"
                            placeholder="your@email.com"
                            autoComplete="email"
                            autoFocus
                            required
                            fullWidth
                            variant="outlined"
                            color={emailError ? 'error' : 'primary'}
                            sx={{ariaLabel: 'email'}}
                        />
                    </FormControl>
                    <FormControl>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <FormLabel sx={{color: 'text.primary'}} htmlFor="password">Password</FormLabel>
                            {/*<Link*/}
                            {/*    component="button"*/}
                            {/*    onClick={handleClickOpen}*/}
                            {/*    variant="body2"*/}
                            {/*    sx={{alignSelf: 'baseline'}}*/}
                            {/*>*/}
                            {/*    Forgot your password?*/}
                            {/*</Link>*/}
                        </Box>
                        <TextField
                            error={passwordError}
                            helperText={passwordErrorMessage}
                            name="password"
                            placeholder="••••••"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            autoFocus
                            required
                            fullWidth
                            variant="outlined"
                            color={passwordError ? 'error' : 'primary'}
                        />
                    </FormControl>
                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                    {/*    label="Remember me"*/}
                    {/*/>*/}
                    {/*<ForgotPassword open={open} handleClose={handleClose}/>*/}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={validateInputs}
                    >
                        Sign in
                    </Button>
                    <Typography sx={{textAlign: 'center'}}>
                        Don&apos;t have an account?{' '}
                        <span>
        <Link to={'/register'}>Sign up</Link>
    </span>
                    </Typography>
                </Box>
            </Card>
        </Stack>
    );
}