import React from 'react';
import {FaSearch, FaUser, FaUpload, FaLock, FaCalendar} from 'react-icons/fa';
import NavItem from "./NavItem";
import {useAuth} from "../../auth/AuthProvider";
import {Link, useNavigate} from "react-router-dom";
import {Box, List} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import Logo from 'src/assets/Logo.svg';

interface Props {
    navSelection: string
    setNavSelection: Function
}

export default function Navbar(props: Props) {
    const {user} = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Box sx={{
            bgcolor: theme.palette.secondary.main,
        }}>

            <Box sx={{mb: 3}}>
                <img src={Logo} style={{width: '180px', height: '180px'}}/>
            </Box>

            <List sx={{textDecoration: 'none'}}>
                <NavItem navItem={'search'} image={<FaSearch size={20}/>} navSelection={props.navSelection}
                         setNavSelection={props.setNavSelection}/>
                <NavItem navItem={'profile'} image={<FaUser size={20}/>} navSelection={props.navSelection}
                         setNavSelection={props.setNavSelection}/>
                {user.is_admin &&
                    <NavItem navItem={'meeting'} image={<FaCalendar size={20}/>} navSelection={props.navSelection}
                             setNavSelection={props.setNavSelection}/>}
                {user.is_admin &&
                    <NavItem navItem={'upload'} image={<FaUpload size={20}/>} navSelection={props.navSelection}
                             setNavSelection={props.setNavSelection}/>}
                <div onClick={() => navigate('/logout')}>
                    <NavItem navItem={'logout'} image={<FaLock size={20}/>} navSelection={props.navSelection}
                             setNavSelection={props.setNavSelection}/>
                </div>
            </List>
        </Box>
    );
};