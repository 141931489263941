import {useEffect, useRef, useState} from "react";
import axios from "axios";
import SearchBar from "./SearchBar";
import SearchResult from "./SearchResult";
import SearchResponse from "./SearchResponse";
import {TailSpin} from 'react-loader-spinner'
import {useTheme} from "@mui/material/styles";
import {Box} from '@mui/material';

interface Props {
    setSourceObjectIds: Function
    setGraphDepth: Function
}

interface SearchResult {
    object_id: number,
    title: string,
    type: string,
    short_description: string,
}

export default function Search(props: Props) {

    const [searchResponse, setSearchResponse] = useState<string | null>(null);
    const [searchResults, setSearchResults] = useState<SearchResult[] | null>(null);
    const [isLoading, setIsLoading] = useState<Boolean>(false);
    const theme = useTheme();

    const searchHandler = (search: string) => {
        setIsLoading(true);
        axios.post(process.env.REACT_APP_API_BASE_URL + 'search',
            {search: search}
            , {
                withCredentials: true
            })
            .then((response) => {
                console.log(response)
                setSearchResponse(response.data.response);

                const searchResults: SearchResult[] = response.data.search_results.map((result: SearchResult) => ({
                    object_id: result.object_id,
                    title: result.title,
                    type: result.type,
                    short_description: result.short_description
                }));
                setSearchResults(searchResults);

                const sourceObjectIds: number[] = searchResults.map((searchResult) => {
                    return searchResult.object_id;
                });
                props.setSourceObjectIds(sourceObjectIds);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setSearchResults(null)
                setIsLoading(false);
            });
        axios.post(process.env.REACT_APP_API_BASE_URL + 'analytics/search/record',
            {search: search}
            , {
                withCredentials: true
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const selectedResultHandler = (object_id: number) => {
        props.setSourceObjectIds([object_id]);
        props.setGraphDepth(1);
    };

    return (
        <div style={{
            display: 'block', alignItems: 'center', margin: "auto", width: '90%', overflowY: 'scroll'
        }}>
            <SearchBar searchHandler={searchHandler}/>

            {isLoading ? (
                <div style={{
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px'
                }}>
                    <TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color={theme.palette.primary.main}
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            ) : (searchResponse) ? (
                <Box
                    sx={{
                        alignItems: 'center',
                        overflowY: 'auto', // Allows scrolling
                        maxHeight: window.innerHeight - 190, // Dynamic height adjustment
                        marginTop: '10px',
                        maskImage: 'linear-gradient(to bottom, transparent, black 5%, black 95%, transparent)',
                        WebkitMaskImage: 'linear-gradient(to bottom, transparent, black 5%, black 95%, transparent)',
                    }}
                >
                    <SearchResponse content={searchResponse} />
                    {searchResults?.map((result) => (
                        <SearchResult result={result} selectHandler={selectedResultHandler} />
                    ))}
                </Box>

            ) : null}
        </div>
    );
}