import './App.css';
import Login from "./pages/login";
import Logout from "./pages/logout";
import Register from "./pages/register";
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import PrivateRoute from "./components/auth/PrivateRoute";
import FourOhFour from "./pages/404";
import {useAuth} from "./components/auth/AuthProvider";
import Explorer from "./components/explorer/Explorer";
import Profile from "./components/profile/profile";
import Meeting from "./components/meeting/meeting";
import MeetingView from "./components/meeting/meeting/meeting_view";
import Uploader from "./components/uploader";
import * as React from "react";
import {useState} from "react";
import {ThemeProvider} from '@mui/material'
import {theme} from 'src/components/theme/theme';
import RegularPage from "src/pages/RegularPage";

function App() {
    const auth = useAuth()
    const [navSelection, setNavSelection] = useState<string>('search');


    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={auth.user ? <Navigate to="/"/> : <Login/>}/>
                        <Route path="/register" element={auth.user ? <Navigate to="/"/> : <Register/>}/>
                        <Route element={<PrivateRoute/>}>
                            <Route
                                element={<RegularPage navSelection={navSelection} setNavSelection={setNavSelection}/>}>
                                <Route path="/" element={<Explorer/>}/>
                                <Route path="/logout" element={<Logout/>}/>
                                <Route path="/profile" element={<Profile/>}/>
                                <Route path="/meeting" element={<Meeting/>}/>
                                <Route path="/meeting/:id" element={<MeetingView/>}/>
                                <Route path="/search" element={<Explorer/>}>
                                    <Route path="person/:id" element={<Profile/>}/>
                                </Route>
                                <Route path="/graph/:id" element={<Explorer/>}/>
                                <Route path="/upload" element={<Uploader/>}/>
                            </Route>
                        </Route>
                        <Route path="*" element={<FourOhFour/>}/>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
